/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
    "primary": rgb(32,86,2),
    "warning": rgb(243,219,11)
);

@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Shippori+Mincho+B1:wght@400;600&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;1,600&family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  margin: 0;
  //font-family: 'Shippori Mincho B1', serif;
  //font-family: 'Open Sans', sans-serif;
  font-family: 'Lato', sans-serif;
}

.shadow{
  box-shadow: 0 2px 5px 0 rgb(0,0,0,0.16), 0 2px 10px 0 rgb(0,0,0,0.12);
}


h1,h2,h3,h4,h5,h6,.footer{
  //font-family: 'Patrick Hand', cursive;
}

h1,h2{
  padding-bottom: 20px;
}

h3,h4,h5,h6{
  padding-bottom: 15px;
  padding-top: 20px;
}

.form-group{
  margin-top: 0px;
  margin-bottom: 10px;
}

.form-group label{
  margin-bottom: 1px;
}

.form-text.text-muted{
  margin-top: 0px;
}
